import request from '../utils/request'
import config from './config'

// 获取补贴等级设置
export function getSubsidyLevelList(data) {
    return request({
        url: `${config.CIVIL_HOST}/subsidyLevel/getSubsidyLevelList`,
        method: 'post',
        data
    })
}

// 新增补贴等级设置
export function addSubsidyLevel(data) {
    return request({
        url: `${config.CIVIL_HOST}/subsidyLevel/addSubsidyLevel`,
        method: 'post',
        data
    })
}

// 编辑补贴等级设置
export function updateSubsidyLevel(data) {
    return request({
        url: `${config.CIVIL_HOST}/subsidyLevel/updateSubsidyLevel`,
        method: 'post',
        data
    })
}

// 补贴老人资料-分页列表查询
export function getElderList(params) {
    return request({
        url: `${config.CIVIL_HOST}/advancedAgeSubsidy/elderly/page`,
        method: 'get',
        params
    })
}

// 补贴老人资料-导出
export function exportElder(params) {
    return request({
        url: `${config.CIVIL_HOST}/advancedAgeSubsidy/elderly/export`,
        method: 'get',
        responseType: 'blob',
        params
    })
}

// 补贴老人资料-详情查询
export function getElederDetail(params) {
    return request({
        url: `${config.CIVIL_HOST}/advancedAgeSubsidy/elderly/detail`,
        method: 'get',
        params
    })
}

// 申请列表
export function getApplylist(params) {
    return request({
        url: `${config.CIVIL_HOST}/advancedAgeSubsidy/list`,
        method: 'get',
        params
    })
}

// 机构申请服务补贴详情
export function applydetails(params) {
    return request({
        url: `${config.CIVIL_HOST}/advancedAgeSubsidy/details`,
        method: 'get',
        params
    })
}

// 返回高龄补贴等级年龄设置
export function getPensionSubsidyLevelNameList(data) {
    return request({
        url: `${config.CIVIL_HOST}/subsidyLevel/getPensionSubsidyLevelNameList`,
        method: 'post',
        data
    })
}

// 删除高龄补贴等级设置
export function deleteSubsidyLevel(params) {
    return request({
        url: `${config.CIVIL_HOST}/subsidyLevel/deleteSubsidyLevel`,
        method: 'get',
        params
    })
}

// 审核操作
export function applyexamine(data) {
    return request({
        url: `${config.CIVIL_HOST}/advancedAgeSubsidy/examine`,
        method: 'post',
        data
    })
}

// 停发申请-分页列表查询
export function cancelpage(params) {
    return request({
        url: `${config.CIVIL_HOST}/advancedAgeSubsidy/cancel/page`,
        method: 'get',
        params
    })
}

// 停发申请-详情查询
export function canceldetail(params) {
    return request({
        url: `${config.CIVIL_HOST}/advancedAgeSubsidy/cancel/detail`,
        method: 'get',
        params
    })
}

// 停发申请-审批
export function cancelapproval(data) {
    return request({
        url: `${config.CIVIL_HOST}/advancedAgeSubsidy/cancel/approval`,
        method: 'post',
        data
    })
}

// 补贴老人资料-导入模板下载
export function templateexport(params) {
    return request({
        url: `${config.CIVIL_HOST}/advancedAgeSubsidy/elderly/template/export`,
        method: 'get',
        responseType: 'blob',
        params
    })
}

// 补贴老人资料-导入
export function templateimport(params) {
    return request({
        url: `${config.CIVIL_HOST}/advancedAgeSubsidy/elderly/template/import`,
        method: 'get',
        params
    })
}

// 老人补贴报表
export function reportList(params) {
    return request({
        url: `${config.CIVIL_HOST}/advancedAgeSubsidy/reportList`,
        method: 'get',
        params
    })
}

// 老人补贴报表-导出
export function reportListExport(params) {
    return request({
        url: `${config.CIVIL_HOST}/advancedAgeSubsidy/reportList/export`,
        method: 'get',
        responseType: 'blob',
        params
    })
}

// 补贴统计
export function statistics(params) {
    return request({
        url: `${config.CIVIL_HOST}/advancedAgeSubsidy/statistics`,
        method: 'get',
        params
    })
}

// 补贴统计-导出
export function statisticsExport(params) {
    return request({
        url: `${config.CIVIL_HOST}/advancedAgeSubsidy/statistics/export`,
        method: 'get',
        responseType: 'blob',
        params
    })
}
